import React, { Children, ReactChild, ReactNode } from "react";
import { AuditDetails } from "../../../store/Audits/AuditDetails/state";
import { undefNullCheckTr } from "../../../utils";

export const isTrFull = (trList: React.ReactNode[]) =>
    trList.reduce((acc, cur) => acc || (React.isValidElement(cur) && !!cur.props.children), false);

export const State = (props?: AuditDetails) => {
    const tableRowsState = [
        undefNullCheckTr(props?.lastEventTimestamp, "Last event:"),
        undefNullCheckTr(props?.distributorId, "Connection ID:"),
        undefNullCheckTr(props?.buildingId, "Distributor ID:"),
        undefNullCheckTr(props?.zoneId, "Serial number:"),
        undefNullCheckTr(props?.scheduleId, "Schedule ID:"),
        undefNullCheckTr(props?.previousUserId, "Previous user ID:"),
    ];

    return (
        <>{isTrFull(tableRowsState) ? <div className="card">
            <div className="card-header p-1 pl-3">
                <b>State</b>
            </div>
            <div className="card-body py-2">
                <table className="table table-sm text-break">
                    <tbody>
                        {tableRowsState}
                    </tbody>
                </table>
            </div>
        </div> : <></>}
        {props?.mode ? <div className="card">
            <div className="card-header p-1 pl-3">
                <b>Mode</b>
            </div>
            <div className="card-body py-2">
                <table className="table table-sm text-break">
                    <tbody>
                        {undefNullCheckTr(props?.mode?.scheduleEventId, "Schedule event ID:")}
                        {undefNullCheckTr(props?.mode?.scheduleNextEventId, "Next schedule event ID:")}
                        {undefNullCheckTr(props?.mode?.manualSetpoint, "Manual setpoint:")}
                        {undefNullCheckTr(props?.mode?.holdUntilSetpoint, "Hold until setpoint:")}
                        {undefNullCheckTr(props?.mode?.holdUntilEndTime, "Hold until ending:")}
                        {undefNullCheckTr(props?.mode?.holdIsPermanent, "Is hold permanent:")}
                        {undefNullCheckTr(props?.mode?.fallbackMode, "Fallback mode:")}
                    </tbody>
                </table>
            </div>
        </div> : <></>}</>
    );
};