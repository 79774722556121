import React from "react";
import { FilterTermMatchType } from "../../store/search";

export type interval = {field: string, value?: string, type: FilterTermMatchType}
export const auditLoggingInterval = 20;

export const LogInterval = (props: {intervals?: interval[], changeInterval: (interval: interval) => void}) => {
    const today = new Date();
    const defaultFrom = new Date();
    defaultFrom.setDate(today.getDate() - auditLoggingInterval);

    const [to, setTo] = React.useState<Date>(today);
    const [from, setFrom] = React.useState<Date>(defaultFrom);
    const handleChangeInDate =
    (event : React.ChangeEvent<HTMLInputElement>, boundary: FilterTermMatchType.LessThanOrEquals | FilterTermMatchType.GreaterThanOrEquals) => {
        const value : any = event.target.valueAsNumber;
        const date = new Date(value);
        if (!isNaN(value) && date) {
            props.changeInterval({ field: "timestamp", value: date.toISOString(), type: boundary });

            if (boundary === FilterTermMatchType.GreaterThanOrEquals) {
                setFrom(date);
            } else {
                setTo(date);
            }
        } else {
            // the user wants the interval cleared, changeInterval filters out this filter
            props.changeInterval({ field: "timestamp", type: boundary });
        }
    };

    const formatDate = (date: Date) => {
        const new_date = new Date();
        new_date.setDate(date.getDate());
        new_date.setHours(new_date.getHours() + 2);

        // to set the date as the value of the input it need to be in ISO.
        // That would also roll it back two hours, it's compensated therefore
        return new_date.toISOString().slice(0, 16);
    };

    return <>
        <div className="d-flex m-2" style={{ height: "fit-content" }}>
            <span className="m-2">From:</span>
            <input
                name="startAt"
                type="datetime-local"
                className="form-control"
                value={formatDate(from)}
                onChange={event => handleChangeInDate(event, FilterTermMatchType.GreaterThanOrEquals)}></input>

            <span className="m-2">To:</span>
            <input
                name="startAt"
                type="datetime-local"
                className="form-control"
                value={formatDate(to)}
                onChange={event => handleChangeInDate(event, FilterTermMatchType.LessThanOrEquals)}></input>
        </div>
    </>;
};