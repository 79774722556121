import React from "react";
import { AuditDetails } from "../../../store/Audits/AuditDetails/state";
import { undefNullCheckTr } from "../../../utils";
import { isTrFull } from "./State";

export const Connection = (props?: AuditDetails) => {
    const tableRowsConnection = [
        undefNullCheckTr(props?.connectionId, "Connection ID:"),
        undefNullCheckTr(props?.distributorId, "Distributor ID:"),
        undefNullCheckTr(props?.serialNumber, "Serial number:"),
        undefNullCheckTr(props?.serverTime, "Server time:"),
        /* TODO fine graining */
        undefNullCheckTr(props?.lastEventTimestamp, "Latest change/last message/last attempt:"),
    ];

    return (
        <>{isTrFull(tableRowsConnection) ? <div className="card">
            <div className="card-header p-1 pl-3">
                <b>Connection</b>
            </div>
            <div className="card-body py-2">
                <table className="table table-sm text-break">
                    <tbody>
                        {tableRowsConnection}
                    </tbody>
                </table>
            </div>
        </div> : <></>}
        {/* reducer can populate messagePayload with undefined timestamp, which falsely gives it a defined nature*/}
        {props?.messagePayload && props.messagePayload.startTime ? <div className="card">
            <div className="card-header p-1 pl-3">
                <b>Message</b>
            </div>
            <div className="card-body py-2">
                <table className="table table-sm text-break">
                    <tbody>
                        {undefNullCheckTr(props?.messagePayload?.temperatureLimitMax, "Temperature limit max:")}
                        {undefNullCheckTr(props?.messagePayload?.startTime, "Start time:")}
                        {undefNullCheckTr(props?.messagePayload?.endTime, "End time:")}
                        {undefNullCheckTr(props?.messagePayload?.changeTimestamp, "Change timestamp:")}
                    </tbody>
                </table>
            </div>
        </div> : <></>}</>
    );
};