import React from "react";
import { AuditDetails } from "../../../store/Audits/AuditDetails/state";
import { undefNullCheckTr } from "../../../utils";
import { isTrFull } from "./State";

export const Linking = (props?: AuditDetails) => {
    const tableRowsLinking = [
        undefNullCheckTr(props?.lastEventTimestamp, "Last event:"),
        undefNullCheckTr(props?.deviceId, "Device ID:"),
        undefNullCheckTr(props?.distributorId, "Distributor ID:"),
        /* TODO fine graining */
        undefNullCheckTr(props?.privateLabelId, "(Account) Private label ID:"),
        undefNullCheckTr(props?.devicePrivateLabelId, "Device Private label ID:"),
    ];

    return (
        <>{isTrFull(tableRowsLinking) ?
            <><div className="card">
                <div className="card-header p-1 pl-3">
                    <b>Linking</b>
                </div>
                <div className="card-body py-2">
                    <table className="table table-sm text-break">
                        <tbody>
                            {tableRowsLinking}
                        </tbody>
                    </table>
                </div>
            </div></> : <></>}
        </>);
};