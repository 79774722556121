import React, { useState } from "react";
import DataTable, { createTheme, IDataTableColumn } from "react-data-table-component";
import { getDataTableTheme } from "../../DataTableThemes";
import SearchRequest, { FilterTermMatch, FilterTermMatchType, defaultSearchRequest } from "../../store/search";
import { RequestState } from "../../store/sharedTypes";
import { formatDateTime, maybePluralize } from "../../utils";
import { MessageBox, MessageBoxType, Spinner } from "../Common";
import DataTableFilterSelector from "../Common/SearchableDataTable/DataTableFilterSelector";
import DataTableSearchField from "../Common/SearchableDataTable/DataTableSearchField";
import { SearchableDataTableProps, selectorAddFilter, selectorRemoveFilter, selectorSelectedFilters, tableChangeLimit, tableChangePage, tableChangeSorting, tableCreateTheme } from "../Common/SearchableDataTable/SearchableDataTable";
import { LogInterval, auditLoggingInterval, interval } from "./LogInterval";
import { AggregationBucket, AuditFilters } from "../../store/Audits/Filters/state";
import { debug } from "console";
import { AuditDetails } from "../../store/Audits/AuditDetails/state";
import { Audit, AuditDetailRequest } from "../../store/Audits/state";
import { useHistory } from "react-router-dom";
import AuditDetailsExpand from "./AuditDetails/AuditDetailsExpand";
import { Chip } from "../Common/Chip";

export interface AuditDataTableProps<T> extends Omit<SearchableDataTableProps<T>, "columns"> {
    filterAggregation: AggregationBucket[];
    onExpand: (request: AuditDetailRequest) => void;
    thermostatSelector?: React.ReactNode;
}

const today = new Date();
const defaultFrom = new Date();
defaultFrom.setDate(today.getDate() - auditLoggingInterval);

const defaultAuditSearch: SearchRequest = {
    ...defaultSearchRequest,
    order: {
        terms: [{
            field: "@timestamp",
            desc: true
        }]
    },
    filter: {
        ...defaultSearchRequest.filter,
        terms: [
            { field: "timestamp", type: FilterTermMatchType.LessThanOrEquals, value: today.toISOString() },
            { field: "timestamp", type: FilterTermMatchType.GreaterThanOrEquals, value: defaultFrom.toISOString() }
        ]
    }
};

export const AuditDataTable = <T extends Audit>(props: AuditDataTableProps<T>) => {
    const [searchRequest, setSearchRequest] = useState<SearchRequest>(defaultAuditSearch ?? defaultSearchRequest);
    const [selected, setSelected] = useState<T[]>([]);
    const [clearRows, setClearRows] = useState<boolean>(props.clearRows ?? false);
    const [query, setQuery] = useState<string>(defaultAuditSearch?.query ?? "");

    const rowsPerPage = props.rowsPerPage ?? [10, 20, 50, 100, 200, 500];
    const displayText = props.displayText ?? "result";
    const pluralSuffix = props.pluralSuffix ?? "s";


    const createdAtFormat = (audit: Audit) => formatDateTime(audit.timestamp as Date);
    const messagePrefix = (message: string) => {
        const trimmed = message.substring(0, message.indexOf("["));
        return trimmed ? trimmed : message;
    };
    const columns: IDataTableColumn[] = [
        { name: "Created at", selector: "timestamp", sortable: true, maxWidth: "150px",
            cell:createdAtFormat },
        { name: "Level", selector: "level", sortable: true, maxWidth: "150px",
            cell: (row: Audit) => <Chip text={row.level} severity={props.filterAggregation ? props.filterAggregation?.filter(item=>item.filterType === "Level").findIndex(item => item.value === row.level) : (-1)} /> },
        { name: "Message", selector: "message", sortable: true, maxWidth: "1400px",
            cell: (row: Audit) => messagePrefix(row.message) }
    ];

    const { search } = props;

    const selectedFilters = (field: string) => selectorSelectedFilters(field, searchRequest);
    const addFilter = (field: string, value: string) => selectorAddFilter(field, value, searchRequest, setSearchRequest);
    const removeFilter = (field: string, value: string) => selectorRemoveFilter(field, value, searchRequest, setSearchRequest);

    const changeLimit = (limit: number) => tableChangeLimit(limit, searchRequest, setSearchRequest);
    const changePage = (page: number) => tableChangePage(page, searchRequest, setSearchRequest);
    const changeSorting = (field: string, descending: boolean) => tableChangeSorting(field, descending, searchRequest, setSearchRequest);

    const changeInterval = (date: interval) => {
        // filter out possible previous filter object of this kind
        const terms = searchRequest.filter.terms.filter(term => term.field !== "timestamp" || term.type !== date.type);

        if (date.value) {
            const filter: FilterTermMatch = { field: date.field, type: date.type, value: date.value };
            terms.push(filter);
        }

        setSearchRequest({ ...searchRequest, filter: { ...searchRequest.filter, terms } });
    };

    React.useEffect(() => {
        search(searchRequest);
    }, [search, searchRequest]);

    tableCreateTheme();

    return (
        <>
            <div className="d-flex flex-column">
                <div className="d-flex" style={{ maxWidth: "800px" }}>
                    <DataTableSearchField
                        query={query}
                        displayText={`${displayText}${pluralSuffix}`}
                        disableButton={props.state === RequestState.InProgress}
                        setQuery={(q) => setQuery(q)}
                        search={() => setSearchRequest({ ...searchRequest, query: query })} />
                </div>
                <div className="d-flex flex-wrap mt-2">
                    <span>
                        <DataTableFilterSelector
                            key={"level"}
                            field={"Level"}
                            selected={selectedFilters("level")}
                            values={props.filterAggregation.filter(item => item.filterType === "Level").map(filter => filter.value)}
                            disableButton={props.state === RequestState.InProgress}
                            addFilter={addFilter}
                            removeFilter={removeFilter}/>
                    </span>
                    <span>
                        <DataTableFilterSelector
                            key={"Category"}
                            field={"Category"}
                            selected={selectedFilters("Category")}
                            values={props.filterAggregation.filter(item => item.filterType === "Category").map(filter => filter.value)}
                            disableButton={props.state === RequestState.InProgress}
                            addFilter={addFilter}
                            removeFilter={removeFilter}/>
                    </span>
                    <LogInterval changeInterval={changeInterval} />
                    <div className="d-flex m-2" style={{ height: "fit-content" }}>
                        {props.thermostatSelector}
                    </div>
                </div>
            </div>
            {props.state === RequestState.Failed ? (
                <MessageBox type={MessageBoxType.Error} title="Failed to search" description={`An error occurred when searching ${displayText}${pluralSuffix}`}>
                    <button
                        className="btn btn-primary mt-3"
                        onClick={() => search(searchRequest)}>Try again</button>
                    <button
                        className="btn btn-primary mt-3 ml-3"
                        onClick={() => search(defaultSearchRequest)}>Reset search</button>
                </MessageBox>
            ) :
                (
                    <DataTable
                        // Options
                        keyField={"id"}
                        noHeader={!props.response}
                        title={!props.response ? null : `Found ${maybePluralize(props.response.total, displayText, pluralSuffix)}`}
                        columns={columns}
                        data={!props.response ? [] : props.response.items}
                        noDataComponent={<p> </p>}

                        // Pagination
                        pagination
                        paginationServer
                        paginationPerPage={searchRequest.page.size}
                        paginationRowsPerPageOptions={rowsPerPage}
                        paginationTotalRows={!props.response ? 0 : props.response.total}
                        onChangeRowsPerPage={size => changeLimit(size)}
                        // page index in API is zero based but in react-data-table-component it starts from 1
                        onChangePage={page => changePage(page - 1)}

                        // Sorting
                        onSort={(column, direction) => changeSorting(column.selector as string, direction === "desc")}
                        sortServer

                        // Expandable row
                        expandableRows
                        expandableRowsComponent={<AuditDetailsExpand/>}
                        onRowExpandToggled={(expanded, row) => {
                            if (expanded && row.fields?.logId) {
                                const category = row.fields.category ? row.fields.category.replace(" ", "") : "Unknown";
                                props.onExpand({ selectedId: row.fields.logId, selectedCategory: category });
                            }
                        }}

                        // Loading/progress
                        progressPending={props.state === RequestState.InProgress}
                        progressComponent={<Spinner />}

                        // Theme
                        theme={props.transparent ? "solarized" : getDataTableTheme()}
                    />)}
        </>
    );
};

export default AuditDataTable;