import React from "react";
import { AuditDetails } from "../../../store/Audits/AuditDetails/state";
import { undefNullCheckTr } from "../../../utils";
import { isTrFull } from "./State";

export const EnergyInsights = (props?: AuditDetails) => {
    const tableRowsEnergy = [
        undefNullCheckTr(props?.lastEventTimestamp, "Last telemetry:")
    ];

    return (
        <>{isTrFull(tableRowsEnergy) ? <div className="card">
            <div className="card-header p-1 pl-3">
                <b>Energy Insights</b>
            </div>
            <div className="card-body py-2">
                <table className="table table-sm text-break">
                    <tbody>
                        {tableRowsEnergy}
                    </tbody>
                </table>
            </div>
        </div> : <></>}
        {/* reducer can populate thermostatTelemetry with undefined timestamp, which falsely gives it a defined nature*/}
        {props?.thermostatTelemetry && props?.thermostatTelemetry.timestamp ? <div className="card">
            <div className="card-header p-1 pl-3">
                <b>Telemetry</b>
            </div>
            <div className="card-body py-2">
                <table className="table table-sm text-break">
                    <tbody>
                        {undefNullCheckTr(props?.thermostatTelemetry?.floorTemperature, "Floor temperature:")}
                        {undefNullCheckTr(props?.thermostatTelemetry?.heatRelayActive, "Is heat relay active:")}
                        {undefNullCheckTr(props?.thermostatTelemetry?.roomTemperature, "Room temperature:")}
                        {undefNullCheckTr(props?.thermostatTelemetry?.setpointTemperature, "Setpoint temperature:")}
                        {undefNullCheckTr(props?.thermostatTelemetry?.timestamp, "Timestamp:")}
                    </tbody>
                </table>
            </div>
        </div> : <></>}</>
    );
};