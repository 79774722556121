import React, { useCallback, useEffect, useState } from "react";
import { IDataTableColumn } from "react-data-table-component";
import { IconContext } from "react-icons";
import { connect } from "react-redux";
import { RouteComponentProps, Router } from "react-router";
import { ApplicationState, IAuthProps } from "../../store";
import { actionCreators } from "../../store/Audits/actionCreators";
import { actionCreators as filterActionCreators } from "../../store/Audits/Filters/actionCreators";
import { actionCreators as detailActionCreators } from "../../store/Audits/AuditDetails/actionCreators";
import { Audit, AuditDetailRequest, AuditState } from "../../store/Audits/state";
import { AggregationBucket, AuditFilters } from "../../store/Audits/Filters/state";
import { CommonState } from "../../store/Common/state";
import { ThermostatsListState } from "../../store/ThermostatsList/state";
import SearchRequest, { FilterTermMatchType, defaultSearchRequest } from "../../store/search";
import { formatDateTime } from "../../utils";
import { withAuthProps, withCommonProps } from "../Common";
import { Page } from "../Common/Page";
import AuditDataTable from "./AuditDataTable";
import { Chip } from "../Common/Chip";
import AuditDetailsExpand from "./AuditDetails/AuditDetailsExpand";
import { auditLoggingInterval } from "./LogInterval";
import { AuditDetailState } from "../../store/Audits/AuditDetails/state";

export type AuditsPageProps =
    AuditState &
    AuditDetailState &
    ThermostatsListState &
    typeof actionCreators &
    typeof filterActionCreators &
    typeof detailActionCreators &
    CommonState &
    IAuthProps &
    RouteComponentProps<{ id: string }>;

// TODO modify default request at AuditDataTable level,
// try if setting up filters could be done there

const ThermostatAuditsPage = (props: AuditsPageProps) => {
    const pageTitle = "Thermostat Audits";
    const [auditFilters, setAuditFilters] = useState<AggregationBucket[]>([]);

    const loadAuditsCallback = useCallback((request) => {
        props.loadThermostatAudits(request, props.match.params.id);
    }, [props.match.params.id]);

    const loadAuditDetailCallback = (request: AuditDetailRequest) => {
        props.setSelected(request);
        props.loadAuditDetail();
    };

    useEffect(() => {
        props.loadFilterAggregations("Thermostat", [props.match.params.id], "Level");
        props.loadFilterAggregations("Thermostat", [props.match.params.id], "Category");
    }, [props.match.params.id]);

    useEffect(() => {
        setAuditFilters(props.filterAggregationResult ? props.filterAggregationResult.items : []);
    }, [props.filterAggregationResult]);

    return <IconContext.Provider value={{ className: "header-react-icons" }}>
        <Page id="thermostats-page" title={pageTitle}>
            <AuditDataTable
                state = {props.auditSearchRequestState}
                response = {props.auditSearchResult}
                search = {loadAuditsCallback}
                onExpand={loadAuditDetailCallback}
                filterAggregation={auditFilters}
                displayText={"Audit"}
                pluralSuffix={"s"}
            />
        </Page>
    </IconContext.Provider>;
};

export default withCommonProps(withAuthProps(connect(
    (state: ApplicationState) => {
        return {
            ...state.audits,
            ...state.thermostatsList,
        };
    },
    { ...actionCreators, ...filterActionCreators, ...detailActionCreators }
)(ThermostatAuditsPage as any)));